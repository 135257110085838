import React, { forwardRef, useRef, useEffect, useState } from "react";
import { Button } from "../../../components/CustomControl/Button";
import CustomTable from "components/CustomTable/CustomTable";
import {
  DeleteOutline,
  Edit,
  ViewList,
  InsertCommentTwoTone,
} from "@material-ui/icons";
import swal from "sweetalert";
import {
  apiCall,
  apiOption,
  LoginUserInfo,
  language,
} from "../../../actions/api";
import ExecuteQueryHook from "../../../components/hooks/ExecuteQueryHook";

const SavingsHistoryModal = (props) => {
  console.log("props modal: ", props);
  const serverpage = "pgentryform"; // this is .php server page
  const [currentInvoice, setInvoiceCurrentRow] = useState(props.currentInvoice);
  const [currentRowSavingsHistory, setCurrentRowSavingsHistory] = useState([]);
  const [pgInfo, setPGInfo] = useState([]);

  const [errorObject, setErrorObject] = useState({});
  const UserInfo = LoginUserInfo();
  const { isLoading, data: dataList, error, ExecuteQuery } = ExecuteQueryHook(); //Fetch data
  const [
    listSavingsHistoryEditPanelToggle,
    setSavingsHistoryListEditPanelToggle,
  ] = useState(true);

  useEffect(() => {
    //console.log("modal currentRow --",currentRow);
    //getData();
    getDataList();
  }, [currentInvoice]);

  function modalClose() {
    console.log("props modal: ", props);
    // props.modalCallback("close");
    props.modalCallback("close", dataList);
  }

  const addData = () => {
    // console.log("rowData: ", rowData);
    // console.log("dataList: ", dataList);

    setCurrentRowSavingsHistory({
      id: "",
      PGId: currentInvoice.id,
      SavingsRate: "",
      FromDate: "",
      ToDate: "",
      TotalDeposit: "",
    });
    /* openModal(); */
    setSavingsHistoryListEditPanelToggle(false);
  };

  const editData = (rowData) => {
    // console.log("rowData: ", rowData);
    // console.log("dataList: ", dataList);

    setCurrentRowSavingsHistory(rowData);
    /*  openModal(); */
    setSavingsHistoryListEditPanelToggle(false);
  };

  const deleteData = (rowData) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      dangerMode: true,
    }).then((allowAction) => {
      if (allowAction) {
        deleteApi(rowData);
      }
    });
  };

  function deleteApi(rowData) {
    let params = {
      action: "deleteSavingsHistoryData",
      lan: language(),
      UserId: UserInfo.UserId,
      rowData: rowData,
    };

    // apiCall.post("productgroup", { params }, apiOption()).then((res) => {
    apiCall.post(serverpage, { params }, apiOption()).then((res) => {
      console.log("res: ", res);
      props.propsmasterProps.openNoticeModal({
        isOpen: true,
        msg: res.data.message,
        msgtype: res.data.success,
      });
      getDataList();
    });
  }

  const columnList = [
    { field: "rownumber", label: "SL", align: "center", width: "3%" },
    // { field: 'SL', label: 'SL',width:'10%',align:'center',visible:true,sort:false,filter:false },
    {
      field: "SavingsRate",
      label: "Rate",
      align: "right",
      visible: true,
      sort: true,
      filter: true,
      width: "10%",
    },
    {
      field: "FromDate",
      label: "From Date",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
      width: "25%",
    },

    {
      field: "ToDate",
      label: "To Date",
      align: "left",
      visible: true,
      sort: true,
      filter: true,
      width: "25%",
    },

    {
      field: "TotalDeposit",
      label: "Total Deposit",
      align: "right",
      visible: true,
      sort: true,
      filter: true,
      width: "20%",
    },

    {
      field: "custom",
      label: "Action",
      width: "10%",
      align: "center",
      visible: true,
      sort: false,
      filter: false,
    },
  ];

  /**Get data for table list */
  function getDataList() {
    let params = {
      action: "getSavingsHistoryDataList",
      lan: language(),
      UserId: UserInfo.UserId,
      PGId: currentInvoice.id,
      /*     DivisionId: currDivisionId,
        DistrictId: currDistrictId,
        UpazilaId: currUpazilaId,
        FarmerStatusId: currFarmerStatusId, */
    };
    // console.log('LoginUserInfo params: ', params);

    ExecuteQuery(serverpage, params);
  }

  /** Action from table row buttons*/
  function actioncontrol(rowData) {
    // console.log("UserInfo", UserInfo);
    // console.log("StatusChangeAllow", UserInfo.StatusChangeAllow);
    let StatusChangeAllow = UserInfo.StatusChangeAllow;
    // let sub = StatusChangeAllow.includes("Submit");
    // console.log('sub: ', sub);

    return (
      <>
        {/* <Edit
              className={"table-edit-icon"}
              onClick={() => {
                editData(rowData);
              }}
            /> */}

        {/* <DeleteOutline
              className={"table-delete-icon"}
              onClick={() => {
                deleteData(rowData);
              }}
            />  */}

        {/* {rowData.BPosted === 0 && ( */}
        {(UserInfo.Settings.AllowEditApprovedData == "1" ||
          rowData.StatusId === 1) && (
          <Edit
            className={"table-edit-icon"}
            onClick={() => {
              editData(rowData);
            }}
          />
        )}

        {/* {rowData.BPosted === 0 && ( */}
        {rowData.StatusId === 1 && (
          <DeleteOutline
            className={"table-delete-icon"}
            onClick={() => {
              deleteData(rowData);
            }}
          />
        )}

        {rowData.StatusId != 1 &&
          UserInfo.Settings.AllowEditApprovedData == "0" && (
            <ViewList
              className={"table-view-icon"}
              onClick={() => {
                editData(rowData);
              }}
            />
          )}
      </>
    );
  }

  const backToList = () => {
    setSavingsHistoryListEditPanelToggle(true); // true = show list and hide add/edit panel
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...currentRowSavingsHistory };
    data[name] = value;

    setCurrentRowSavingsHistory(data);

    setErrorObject({ ...errorObject, [name]: null });

    // Calculate "TotalDeposit" based on SavingsRate, FromDate, and ToDate
    /* const savingsRate = parseFloat(data["SavingsRate"]) || 0;
    const fromDate = data["FromDate"] ? new Date(data["FromDate"]) : null;
    const toDate = data["ToDate"] ? new Date(data["ToDate"]) : new Date();

    if (fromDate && savingsRate > 0) {
      let monthCount = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
      monthCount += toDate.getMonth() - fromDate.getMonth();

      if (toDate.getDate() < fromDate.getDate()) {
        monthCount--;
      }

      monthCount = Math.max(0, monthCount + 1);

      data["TotalDeposit"] = Math.ceil(monthCount) * savingsRate;
    } else {
      data["TotalDeposit"] = "";
    } */

    const savingsRate = parseFloat(data["SavingsRate"]) || 0;
    const fromDate = data["FromDate"] ? new Date(data["FromDate"]) : null;
    const toDate = data["ToDate"] ? new Date(data["ToDate"]) : null;

    if (fromDate && savingsRate > 0 && toDate) {
      let monthCount = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
      monthCount += toDate.getMonth() - fromDate.getMonth();

      if (toDate.getDate() < fromDate.getDate()) {
        monthCount--;
      }

      monthCount = Math.max(0, monthCount + 1);

      data["TotalDeposit"] = Math.ceil(monthCount) * savingsRate;
    } else {
      data["TotalDeposit"] = 0;
    }
  };

  const validateForm = () => {
    // let validateFields = ["PGName", "DiscountAmount", "DiscountPercentage"]
    let validateFields = ["SavingsRate", "FromDate"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!currentRowSavingsHistory[field]) {
        errorData[field] = "validation-style";
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function addEditAPICall() {
    if (validateForm()) {
      let params = {
        action: "dataAddEditSavingsHistory",
        lan: language(),
        UserId: UserInfo.UserId,
        rowData: currentRowSavingsHistory,
      };

      apiCall.post(serverpage, { params }, apiOption()).then((res) => {
        // console.log('res: ', res);

        props.propsmasterProps.openNoticeModal({
          isOpen: true,
          msg: res.data.message,
          msgtype: res.data.success,
        });

        // console.log('props modal: ', props);
        if (res.data.success === 1) {
          // props.modalCallback("addedit");
          setSavingsHistoryListEditPanelToggle(true);
          getDataList();
        }
      });
    }
  }

  return (
    <>
      {/* <!-- GROUP MODAL START --> */}
      <div id="groupModal" class="modal">
        {/* <!-- Modal content --> */}
        <div class="modal-content-sm">
          <div class="modalHeader topHeader">
            <h4>Savings History</h4>

            {!listSavingsHistoryEditPanelToggle ? (
              <>
                <Button
                  label={"Back To List"}
                  class={"btnClose"}
                  onClick={backToList}
                />
              </>
            ) : (
              <></>
            )}
          </div>

          {/* <div class="subContainer">
                    <div className="App">
                      <CustomTable
                        columns={columnList}
                        rows={dataList ? dataList : {}}
                        actioncontrol={actioncontrol}
                        isLoading={isLoading}
                      />
                    </div>
                  </div> */}

          {listSavingsHistoryEditPanelToggle && (
            <>
              {!listSavingsHistoryEditPanelToggle ? (
                <>
                  <Button
                    label={"Back To List"}
                    class={"btnClose"}
                    onClick={backToList}
                  />
                </>
              ) : (
                <></>
              )}
              <div class="filter-button">
                {UserInfo.StatusChangeAllow.includes("Submit") && (
                  <>
                    <Button
                      label={"Enter Data"}
                      class={"btnAdd"}
                      onClick={addData}
                    />

                    <Button
                      label={"Close"}
                      class={"btnClose"}
                      onClick={modalClose}
                    />
                  </>
                )}
              </div>

              <div class="subContainer">
                <div className="App">
                  <CustomTable
                    columns={columnList}
                    rows={dataList ? dataList : {}}
                    actioncontrol={actioncontrol}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            </>
          )}

          {!listSavingsHistoryEditPanelToggle && (
            <>
              {1 === 1 && (
                <>
                  <div className="formControl-mobile">
                    <label>Rate*</label>
                    <input
                      type="number"
                      id="SavingsRate"
                      name="SavingsRate"
                      placeholder="Enter Rate"
                      class={errorObject.SavingsRate}
                      value={currentRowSavingsHistory.SavingsRate}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  <div class="formControl-mobile">
                    <label>From Date* </label>
                    <input
                      type="date"
                      id="FromDate"
                      name="FromDate"
                      class={errorObject.FromDate}
                      placeholder="Select From Date"
                      value={currentRowSavingsHistory.FromDate}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  <div class="formControl-mobile">
                    <label>To Date</label>
                    <input
                      type="date"
                      id="ToDate"
                      name="ToDate"
                      class={errorObject.ToDate}
                      placeholder="Select To Date"
                      value={currentRowSavingsHistory.ToDate}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  <div className="formControl-mobile">
                    <label>Total Deposit</label>
                    <input
                      type="number"
                      id="TotalDeposit"
                      name="TotalDeposit"
                      disabled="true"
                      placeholder=""
                      value={currentRowSavingsHistory.TotalDeposit}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </>
              )}
            </>
          )}

          <div class="modalItem-mobile">
            {/* <Button label={"Close"} class={"btnClose"} onClick={modalClose} /> */}

            {!listSavingsHistoryEditPanelToggle ? (
              <>
                {currentRowSavingsHistory.id && (
                  <Button
                    label={"Update"}
                    disabled={
                      UserInfo.Settings.AllowEditApprovedData == "1"
                        ? false
                        : currentRowSavingsHistory.StatusId > 1
                    }
                    class={"btnUpdate"}
                    onClick={addEditAPICall}
                  />
                )}
                {!currentRowSavingsHistory.id && (
                  <Button
                    label={"Save"}
                    class={"btnSave"}
                    onClick={addEditAPICall}
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* <!-- GROUP MODAL END --> */}
    </>
  );
};

export default SavingsHistoryModal;
